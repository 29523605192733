<template>
  <v-card
    :width="cardWidth"
    height="100%"
    min-height="352"
    class="rounded-lg d-flex flex-column justify-space-between"
    elevation="24"
  >
    <!--Card Header-->
    <v-app-bar :color="getColorByType" height="56" max-height="56" flat>
      <v-container>
        <v-row class="d-flex align-center">
          <v-col class="ml-n3" cols="10">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-toolbar-title
                  class="aliceBlue--text font-weight-bold mr-auto text-h5"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="white" class="mr-n1 mt-n2" left>
                    {{ getIconByType }}
                  </v-icon>
                  {{ reportInfo.reportName }}
                </v-toolbar-title>
              </template>
              <span>{{ reportInfo.reportName }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-card-actions>
              <ActionButtonsVue
                :buttonsList="actionButtons"
                :color="'aliceBlue'"
              />
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <!--Card Body-->
    <v-card
      flat
      height="100%"
      class="rounded-lg d-flex flex-column justify-space-between"
    >
      <v-container>
        <v-row>
          <v-col>
            <v-img
              :src="reportInfo.powerBiImageUrl || fallBackImage"
              class="rounded my-1"
              :height="imageHeight"
            >
            </v-img>
            <span class="primary--text text-caption d-flex justify-left pt-3">
              {{ reportInfo.powerBiDescription }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>

<script>
import ActionButtonsVue from '@/Core.UI.Domain/Components/ActionButtons';
import { mapActions } from 'vuex';

export default {
  name: 'ReportCard',

  components: {
    ActionButtonsVue,
  },

  props: {
    reportInfo: {
      type: Object,
      required: true,
    },
    messages: {
      type: Number,
      default: 3,
    },
  },

  computed: {
    isRealTimeType() {
      return /real\s*-?\s*time/gi.test(this.reportInfo.reportType);
    },
    isScheduledType() {
      return /scheduled/gi.test(this.reportInfo.reportType);
    },

    getIconByType() {
      if (this.isRealTimeType) {
        return 'mdi-chart-multiline';
      }
      if (this.isScheduledType) {
        return 'mdi-invoice-text-clock-outline';
      }
      return 'mdi-file-chart-outline';
    },

    getColorByType() {
      if (this.isRealTimeType) {
        return 'success';
      }
      if (this.isScheduledType) {
        return 'warning';
      }
      return 'primary';
    },
  },

  data(isntance) {
    return {
      fallBackImage:
        'https://energy360sr.blob.core.windows.net/energy-360-report-images/default-chart.jpg',
      cardWidth: 370,
      imageHeight: 200,
      showMapDialog: false,
      showTooltip: false,
      actionButtons: [
        {
          icon: 'chevron-right',
          toolTip: 'Go to report',
          action: () => {
            // <router-link :to="`/reports/${report.reportName}`">
            isntance.handleButtonAction(
              'ReportDetailsRoute',
              isntance.reportInfo.reportName
            );
          },
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      setDefaultSite: 'session/setDefaultSite',
    }),

    handleButtonAction(name, reportName = '') {
      this.$store.commit(
        'ViewSupportStore/ReportsStore/SET_REPORT_INFO',
        this.reportInfo
      );
      // This function will be replaced when the new views are ready
      this.$router.push({
        name,
        params: {
          reportName,
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.v-btn::v-deep
  .v-btn__content
    opacity: 1 !important

span
  letter-spacing: 0px
</style>
