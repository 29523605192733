<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-list-item>
            <v-list-item-avatar size="44" class="ml-2">
              <v-img :src="adminData.userPicture" />
            </v-list-item-avatar>

            <v-list-item-title
              class="aliceBlue--text font-weight-bold cut-name"
            >
              {{ adminData.userName }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
      <!--Parents-->
      <v-list>
        <v-list-group
          v-for="item in allowedItems"
          :key="item.title"
          @click="item.action ? item.action() : handleClick(item)"
          no-action
        >
          <template v-slot:appendIcon>
            <v-list-item-icon class="ma-auto" v-if="hasChildren(item)">
              <v-icon small color="primary"> mdi-chevron-down </v-icon>
            </v-list-item-icon>
            <div else></div>
          </template>

          <template v-slot:prependIcon>
            <v-list-item-icon class="ma-auto">
              <v-icon color="primary">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content class="ml-n6 primary--text">
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <!--Children-->
          <v-list v-if="hasChildren(item)">
            <v-list-group
              v-for="child in item.items"
              :key="child.title"
              @click="handleClick(child)"
              no-action
            >
              <template v-slot:appendIcon>
                <v-list-item-icon
                  class="my-auto ml-5"
                  v-if="hasChildren(child)"
                >
                  <v-icon small color="primary"> mdi-chevron-down </v-icon>
                </v-list-item-icon>
                <div else></div>
              </template>

              <template v-slot:prependIcon>
                <v-list-item-icon class="my-auto ml-5">
                  <v-icon color="primary">
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content class="ml-n13 primary--text">
                  <v-list-item-title>
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <!--Grandchildren-->
              <v-list v-if="hasChildren(child)">
                <v-list-group
                  v-for="grandChild in child.items"
                  :key="grandChild.title"
                  @click="handleClick(grandChild)"
                  no-action
                >
                  <template v-slot:appendIcon>
                    <v-list-item-icon
                      class="my-auto ml-10"
                      v-if="hasChildren(grandChild)"
                    >
                      <v-icon small color="primary"> mdi-chevron-down </v-icon>
                    </v-list-item-icon>
                    <div else></div>
                  </template>

                  <template v-slot:prependIcon>
                    <v-list-item-icon class="my-auto ml-10">
                      <v-icon color="primary">
                        {{ grandChild.icon }}
                      </v-icon>
                    </v-list-item-icon>
                  </template>
                  <template v-slot:activator>
                    <v-list-item-content class="ml-n13 primary--text">
                      <v-list-item-title>
                        {{ grandChild.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-group>
              </v-list>
            </v-list-group>
          </v-list>
        </v-list-group>
      </v-list>
    </v-bottom-sheet>
    <TermsOfService
      :show="showTermsOfService"
      @onclose="handleTermsOfServiceClose"
      @onhastermsofservice="handleHasTermsOfService"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// This import will be replaced by the new codebase when it's ready
import TermsOfService from '@/components/TermsOfService.vue';

export default {
  name: 'AdminMenu',

  components: {
    TermsOfService,
  },

  props: {
    adminData: {
      type: Object,
      default: () => ({}),
    },
    onClickParent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('session', ['hasNewTermsOfServices']),

    showAdminMenu() {
      // this function will be refactored in the future
      let isAdmin = false;
      if (this.$can('read', 'unconfigured')) {
        return isAdmin;
      } else {
        isAdmin = this.$can('create', 'users');
      }

      return isAdmin;
    },

    allowedItems() {
      return this.items.filter((item) => item.active());
    },
  },

  methods: {
    ...mapActions('session', ['logout']),
    hasChildren(item) {
      return item.items && item.items.length;
    },

    handleClick(item) {
      if (item.to) {
        this.sheet = false;
        this.$router.push(item.to);
      }
    },

    openTermsOfService() {
      this.showTermsOfService = true;
    },
    handleTermsOfServiceClose() {
      this.showTermsOfService = false;
    },
    handleHasTermsOfService(hasTermsOfService) {
      this.hasTermsOfService = hasTermsOfService;
    },
  },

  watch: {
    onClickParent(val) {
      this.sheet = val;
    },
    sheet(val) {
      this.$emit('update:onClickParent', val);
    },
  },

  data: (instance) => ({
    showTermsOfService: false,
    hasTermsOfService: { type: Boolean, default: false },
    sheet: false,
    items: [
      {
        icon: 'mdi-account-cog',
        to: '',
        active: () => instance.showAdminMenu,
        items: [
          {
            icon: 'mdi-account-box-multiple',
            title: 'Users',
            to: { name: 'UserIndex' },
          },
          {
            icon: 'mdi-office-building-marker',
            title: 'Sites',
            to: '',
            items: [
              {
                icon: 'mdi-map-marker-multiple',
                title: 'All Sites',
                to: { name: 'SitesRoute' },
              },
              {
                icon: 'mdi-hand-coin-outline',
                title: 'ROI Predictions',
                to: { name: 'ROIPredictions' },
              },
              {
                icon: 'mdi-home-lightning-bolt',
                title: 'Utility Companies',
                to: { name: 'UtilityCompanies' },
              },
            ],
          },
          {
            icon: 'mdi-chart-box',
            title: 'Reports',
            to: { name: 'AdminReportIndexRoute' },
          },
          {
            icon: 'mdi-sync-circle',
            title: 'ADF-Sync',
            to: { name: 'ADFSyncIndex' },
          },
          {
            icon: 'mdi-account-sync',
            title: 'Refresh Auth0 Role Cache',
            to: { name: 'UsersRolesCache' },
          },
        ],
        title: 'Administration',
      },
      {
        title: 'Message Center',
        to: { name: 'MessageCenter' },
        icon: 'mdi-message-text-fast-outline',
        active: () => instance.showAdminMenu,
      },
      {
        icon: 'mdi-shield-lock',
        to: '',
        active: () => instance.showAdminMenu,
        items: [
          {
            icon: 'mdi-shield-home',
            title: 'Permissions - Core',
            to: { name: 'Permissions' },
          },
        ],
        title: 'Security',
      },
      {
        icon: 'mdi-chart-timeline-variant-shimmer',
        to: '',
        active: () => instance.showAdminMenu,
        items: [
          {
            icon: 'mdi-gauge',
            to: '',
            title: 'Egauges',
            items: [
              {
                icon: 'mdi-gauge',
                title: 'My Egauges',
                to: { name: 'SubMeterEgaugeIndex' },
              },
              {
                icon: 'mdi-database-sync',
                title: 'Egauge Data Recovery',
                to: { name: 'SubMeterEgaugeDataRecovery' },
              },
            ],
          },
          {
            icon: 'mdi-alpha-j-box',
            to: '',
            title: 'Jace',
            items: [
              {
                icon: 'mdi-alpha-j-box',
                title: 'My Jace Monitors',
                to: { name: 'SubMeterJaceIndex' },
              },
            ],
          },
          {
            icon: 'mdi-monitor-shimmer',
            to: '',
            title: 'Manual Monitors',
            items: [
              {
                icon: 'mdi-monitor-shimmer',
                title: 'My Manual Monitors',
                to: { name: 'SubMeterManualMonitorIndex' },
              },
            ],
          },
          {
            icon: 'mdi-monitor-account',
            title: 'Virtual Monitors',
            to: { name: 'VirtualMonitorIndex' },
          },
          {
            icon: 'mdi-alpha-a-box',
            to: '',
            title: 'Aginova',
            items: [
              {
                icon: 'mdi-alpha-a-box',
                title: 'My Aginova Monitors',
                to: { name: 'AginovaMonitorsList' },
              },
              {
                icon: 'mdi-alpha-a-box-outline',
                title: 'Aginova Login',
                to: { name: 'AginovaLoginIndex' },
              },
              {
                icon: 'mdi-database-sync',
                title: 'Aginova Data Recovery',
                to: { name: 'AginovaDataRecovery' },
              },
            ],
          },
          {
            icon: 'mdi-alpha-p-box',
            to: '',
            title: 'Piera',
            items: [
              {
                icon: 'mdi-alpha-p-box',
                title: 'My Piera Monitors',
                to: { name: 'PieraMonitorsList' },
              },
              {
                icon: 'mdi-alpha-p-box-outline',
                title: 'Piera Login',
                to: { name: 'PieraLoginIndex' },
              },
            ],
          },
          {
            icon: 'mdi-ray-end',
            title: 'End Uses',
            to: { name: 'EgaugeEndUseIndex' },
          },
          {
            icon: 'mdi-leak',
            title: 'Sensors',
            to: { name: 'EgaugeSensorIndex' },
          },
          {
            icon: 'mdi-speedometer',
            to: { name: 'UtilityMeterIndex' },
            title: 'Utility Meters',
          },
          {
            icon: 'mdi-smoke-detector-variant',
            to: { name: 'EquipmentIndex' },
            title: 'Equipment',
          },
          {
            icon: 'mdi-map-marker-radius',
            to: { name: 'SiteLocationIndex' },
            title: 'Site Location',
          },
        ],
        title: 'Monitors',
      },
      {
        icon: 'mdi-timer-cog-outline',
        to: '',
        active: () => instance.showAdminMenu,
        items: [
          {
            icon: 'mdi-shield-account',
            title: 'Pelican Permissions',
            to: { name: 'ControlPermissionsIndex' },
          },
        ],
        title: 'Controls',
      },
      {
        icon: 'mdi-finance',
        items: [
          {
            icon: 'mdi-collage',
            title: 'Models',
            to: { name: 'Models' },
            active: () => instance.$can('read', 'models'),
          },
          {
            icon: 'mdi-chart-gantt',
            title: 'Projects',
            to: { name: 'AnalyticsProjects' },
            active: () => instance.$can('read', 'projects'),
          },
          {
            icon: 'mdi-cog-off',
            title: 'Non-Routine Events',
            to: { name: 'NonRoutineEvents' },
            active: () => instance.$can('read', 'non-routine-events'),
          },
          {
            icon: 'mdi-gauge',
            title: 'Measures/Oportunities',
            to: { name: 'AnalyticsMeasures' },
            active: () => instance.$can('read', 'measures'),
          },
        ],
        title: 'Analytics',
        to: '',
        active: () => instance.$can('read', 'analytics'),
      },
      {
        icon: 'mdi-text-box-check-outline',
        to: '',
        active: () => true,
        title: 'Terms of Services',
        action: () => instance.openTermsOfService(),
      },
      {
        icon: 'mdi-logout',
        to: '',
        active: () => true,
        title: 'Logout',
        action: () => instance.logout(),
      },
    ],
  }),
};
</script>
<style lang="sass" scoped>
.cut-name
  white-space: nowrap
  overflow: hidden
  text-align: left
  display: inline-block
  text-overflow: ellipsis
  width: calc(80%)
</style>
